html, body, #root {
    width: 100%;
    height: 100%;
}

input:focus {
    outline: none;
}

div[class^="css-view-"]{
    min-height: unset !important;
}